export const isJSON = str => {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
};

/**
 * Checks if a JWT access token exists in local storage.
 * 
 * @returns {boolean} true if the token exists, false otherwise.
 */
export const getAccessToken = () => {
  if (localStorage.getItem('jwt_access_token')) {
    return true;
  }
  return false;
};

/**
 * Generates a globally unique identifier (GUID) as a string.
 * This function creates a GUID by concatenating two randomly generated
 * hexadecimal segments.
 * 
 * @returns {string} A string representing the GUID.
 */
export function generateGUID() {
	function S4() {
		return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
	}
	return S4() + S4();
}

/**
 * Compare two dates and check if they are within a certain number of minutes of each other.
 * This function first checks if the two dates are the same day, and if so, calculates the
 * absolute difference in milliseconds and converts it to minutes. If the difference is
 * less than the specified number of minutes, the function returns true. Otherwise, it
 * returns false.
 * 
 * @param {string|Date} date1 The first date to compare.
 * @param {string|Date} date2 The second date to compare.
 * @param {number} [minutes=30] The number of minutes to check for.
 * @returns {boolean} True if the dates are within the specified number of minutes of each
 *  other, false otherwise.
 */
export const compareDatesByMinutes = (date1, date2, minutes = 30) => {
  // Convert both dates to timestamps
  let timestamp1 = new Date(date1).getTime();
  let timestamp2 = new Date(date2).getTime();
  // If dates are different, return false
  let datePart1 = new Date(date1).toDateString();
  let datePart2 = new Date(date2).toDateString();
  if (datePart1 !== datePart2) {
    return false;
  }
  // Calculate the absolute difference in milliseconds
  let diffMilliseconds = Math.abs(timestamp1 - timestamp2);
  // Convert milliseconds to minutes
  let diffMinutes = diffMilliseconds / (1000 * 60);
  // Check if the difference is less than minutes(default = 30 minutes) minutes
  return diffMinutes < minutes;
}
